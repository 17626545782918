import { useRuntimeConfig } from '#app'
import { combineUrlWithParams } from '~/utils/httpUtils'

const { useCustomFetch } = useFetchBase()

const base = '/api/message'
const module = '/customerTags'
const path = base + module

// request(REQ) Api  --------------------------------
const getAllCustomerTagsREQ = path + '/list' // 獲取客戶標籤列表
const searchCustomerTagsREQ = path + '/list/q' // 搜索客戶標籤
const addCustomerTagREQ = path + '/add' // 新增客戶標籤
const editCustomerTagREQ = path + '/update' // 更新客戶標籤
const deleteCustomerTagREQ = path + '/del' // 刪除客戶標籤

// request(REQ) Function  --------------------------------

/**
 * 獲取所有客戶標籤列表
 * @param {*} params
 * @param {*} callback
 * @returns
 */
export const getAllCustomerTagListFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + combineUrlWithParams(getAllCustomerTagsREQ, params)
  return useCustomFetch(api, {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 搜索客戶標籤
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns 分頁數據
 */
export const getCustomerTagsFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + combineUrlWithParams(searchCustomerTagsREQ, params)
  return useCustomFetch(api, {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 新增客戶標籤
 * @param {Object} formData 表單資料
 * @param {Object} callback 回調函數
 * @returns
 */
export const addCustomerTagFn = (formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + addCustomerTagREQ, {
    method: 'POST',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 更新客戶標籤
 * @param {Object} params 查詢參數
 * @param {Object} formData 表單資料
 * @param {Object} callback 回調函數
 * @returns
 */
export const editCustomerTagFn = (params, formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + combineUrlWithParams(editCustomerTagREQ, params), {
    method: 'PUT',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 刪除客戶標籤
 * @param {Object} params 查詢參數
 * @param {Object} callback 回掉函數
 * @returns
 */
export const deleteCustomerTagFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + combineUrlWithParams(deleteCustomerTagREQ, params), {
    method: 'DELETE',
    onResponse: callback
  })
}
